
.faqContainer {
    padding: 1.5rem;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width:100%;
  }
  
  .header {
    margin-bottom: 20px;
  }
  .faqContent {
    width:100%;
    flex-direction: column;
    gap: 0.7rem;
  }
  
  .faqItem {
    width: 100%;
    margin-bottom: 10px;
    padding-bottom: 1rem;
    justify-content: space-between;
  }
  
  .faqItem:last-child {
    border-bottom: none; 
  }
  
  .question {
    cursor: pointer;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }
  .question_content{
    width: 100%;
    line-height: 22px;
  
  }
  
  
  /* .question::after {
    content: '<';  
    font-size: 16px;
    transition: transform 0.3s;
    display: inline-block; 
    transform: rotate(90deg); 
  }
  
  .question.open::after {
    transform: rotate(-90deg); 
  } */
  
  
  /* .question.open::after {
    transform: rotate(-180deg);
  } */
  
  
  